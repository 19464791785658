import React from 'react';
import PropTypes from 'prop-types';
import HeadingGradient, { HEADINGS } from '@components/HeadingGradient';
import SimpleBlockContent from '@components/SimpleBlockContent';

const Hero = ({ homepage, ...props }) => (
  <section {...props}>
    <HeadingGradient type={HEADINGS.h1} className="mb-8 sm:mb-16">
      {homepage.heading}
    </HeadingGradient>
    <SimpleBlockContent blocks={homepage._rawContent} />
  </section>
);

Hero.propTypes = {
  homepage: PropTypes.shape().isRequired
};

export default Hero;
