import React from 'react';
import PropTypes from 'prop-types';
import HeadingGradient, { HEADINGS } from '@components/HeadingGradient';
import SimpleBlockContent from '@components/SimpleBlockContent';
import Link from '@components/Link';
import clsx from 'clsx';
import { getLowerDashed } from '@utils';

const Movement = ({ data, ...props }) => {
  const isMainBranch = process.env.BRANCH === 'main';

  return (
    <section {...props}>
      <HeadingGradient type={HEADINGS.h2} className="mb-8 sm:mb-16">
        {data.heading}
      </HeadingGradient>
      <SimpleBlockContent blocks={data._rawContent} />
      <div className="flex flex-col space-y-8 justify-evenly lg:flex-row lg:mt-16 lg:space-y-0">
        {data.ctas.map((cta) => (
          <Link
            className={clsx('w-full lg:w-auto', {
              'analytics-bottom-cta': isMainBranch,
              'analytics-top-cta': !isMainBranch
            })}
            id={`movement-cta-${getLowerDashed(cta.title)}`}
            key={cta.link}
            href={cta.link}
          >
            {cta.title}
          </Link>
        ))}
      </div>
    </section>
  );
};

Movement.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    _rawContent: PropTypes.arrayOf(
      PropTypes.shape({
        blocks: PropTypes.arrayOf({ children: PropTypes.node })
      })
    ),
    ctas: PropTypes.arrayOf(PropTypes.shape())
  }).isRequired
};

export default Movement;
