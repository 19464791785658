import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Button from '@components/Button';
import HeadingGradient, { HEADINGS } from '@components/HeadingGradient';
import StrikerModal from '@components/StrikerModal';
import { Striker } from '@types';
import { MAIN_STRIKERS_TO_SHOW } from '@constants';
import {
  unblockScroll,
  blockScroll,
  neutralizeBrowserBackButton,
  restoreBrowserBackButton
} from '@utils';

const StrikersSection = ({ data, ...props }) => {
  const { strikers, heading } = data;
  const [activeStriker, setActiveStriker] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAllStrikers, setShowAllStrikers] = useState(false);
  const mainStrikers = strikers.slice(0, MAIN_STRIKERS_TO_SHOW);
  const visibleStrikers = showAllStrikers ? strikers : mainStrikers;
  const hasStrikers = Boolean(strikers.length);
  const hasMultipleStrikers = strikers.length > MAIN_STRIKERS_TO_SHOW;
  const displayStrikersButton = hasMultipleStrikers && !showAllStrikers;

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveStriker(null);
    unblockScroll();
    restoreBrowserBackButton();
  };

  useEffect(() => {
    const openModal = () => {
      setIsModalOpen(true);
      blockScroll();
      neutralizeBrowserBackButton(closeModal);
    };

    activeStriker && openModal();
  }, [activeStriker]);

  return (
    <>
      <section {...props}>
        <HeadingGradient type={HEADINGS.h2} className="mb-8 sm:mb-16">
          {heading}
        </HeadingGradient>
        <ul className="grid max-w-screen-xl grid-cols-2 gap-12 mx-auto mb-16 md:mb-32 md:space-y-0 lg:gap-24 justify-items-center sm:grid-cols-3 lg:grid-cols-5">
          {hasStrikers &&
            visibleStrikers.map((striker) => (
              <li
                key={striker.name}
                className="w-28 h-28 sm:w-32 sm:h-32 md:w-40 md:h-40"
              >
                <button
                  className="rounded-full p-0.5 bg-gradient-to-r from-biden-blue to-biden-red w-full h-full"
                  type="button"
                  aria-label={`Open ${striker.name} debt testimony`}
                  onClick={() => setActiveStriker(striker)}
                >
                  <Img
                    className="object-cover w-full h-full overflow-hidden transition-transform transform scale-95 rounded-full hover:scale-100"
                    fluid={striker.avatar.asset.fluid}
                    alt={striker.name}
                  />
                </button>
              </li>
            ))}
        </ul>
        {displayStrikersButton && (
          <Button
            id="show-strikers"
            className="block mx-auto"
            onClick={() => setShowAllStrikers(true)}
          >
            SHOW ALL STRIKERS
          </Button>
        )}
      </section>
      <StrikerModal
        activeStriker={activeStriker}
        strikers={strikers}
        triggerShowAllStrikers={useCallback(() => setShowAllStrikers(true), [])}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

StrikersSection.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    strikers: PropTypes.arrayOf(Striker)
  }).isRequired
};

export default StrikersSection;
