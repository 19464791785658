import PropTypes from 'prop-types';

export const Striker = PropTypes.shape({
  name: PropTypes.string,
  quote: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string
        })
      )
    })
  ),
  avatar: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string
    })
  })
});
