import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

const THRESHOLD = 0.15;

const FadeInAnimation = ({ children }) => {
  const [ref, inView] = useInView({ threshold: THRESHOLD, triggerOnce: true });

  return (
    <div
      ref={ref}
      style={{
        transition: 'opacity 1s',
        transitionDelay: '100ms',
        opacity: inView ? 1 : 0
      }}
    >
      {children}
    </div>
  );
};

FadeInAnimation.propTypes = {
  children: PropTypes.node.isRequired
};

export default FadeInAnimation;
