import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Link = ({ children, className, ...props }) => (
  <div className="flex items-center">
    <a
      target="_blank"
      rel="noreferrer"
      className={clsx(
        'inline-block py-3 mx-auto text-2xl font-bold text-white border-2 border-white rounded-lg px-7 md:py-4 md:px-8 md:text-4xl bg-gradient-to-r from-biden-blue via-biden-red to-biden-red uppercase no-underline text-center',
        className
      )}
      {...props}
    >
      {children}
    </a>
  </div>
);

Link.defaultProps = {
  className: null
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Link;
