import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { CarouselProvider } from 'pure-react-carousel';
import StrikersCarousel from '@components/StrikersCarousel';
import { Striker } from '@types';
import 'pure-react-carousel/dist/react-carousel.es.css';

Modal.setAppElement('#___gatsby');

const StrikerModal = ({
  activeStriker,
  isOpen,
  onClose,
  strikers,
  triggerShowAllStrikers
}) => {
  const indexOfActiveStriker = strikers.findIndex(
    (striker) => striker?.name === activeStriker?.name
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="relative m-auto bg-black max-w-5/6 w-120"
      overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75 flex"
    >
      {/* These four divs handles the gradient border of the striker card */}
      <div className="absolute inset-x-0 top-0 z-10 w-full h-1 bg-biden-blue" />
      <div className="absolute inset-y-0 left-0 z-10 w-1 h-full bg-gradient-to-b from-biden-blue to-biden-red" />
      <div className="absolute inset-y-0 right-0 z-10 w-1 h-full bg-gradient-to-b from-biden-blue to-biden-red" />
      <div className="absolute inset-x-0 bottom-0 z-10 w-full h-1 bg-biden-red" />
      <button
        id="close-modal"
        type="button"
        className="absolute z-20 text-3xl text-gray-300 top-1 lg:text-4xl right-1 material-icons md:-top-10 md:-right-10"
        onClick={onClose}
      >
        close
      </button>
      <CarouselProvider
        className="p-0 overflow-hidden"
        naturalSlideWidth={1}
        naturalSlideHeight={
          typeof window !== 'undefined' && window.innerWidth < 400 ? 1.75 : 1.25
        }
        totalSlides={strikers.length}
        currentSlide={indexOfActiveStriker}
        dragEnabled={false}
        lockOnWindowScroll
        touchEnabled={false}
      >
        <StrikersCarousel
          strikers={strikers}
          triggerShowAllStrikers={triggerShowAllStrikers}
        />
      </CarouselProvider>
    </Modal>
  );
};

StrikerModal.defaultProps = {
  activeStriker: {},
  strikers: []
};

StrikerModal.propTypes = {
  activeStriker: Striker,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  strikers: PropTypes.arrayOf(Striker),
  triggerShowAllStrikers: PropTypes.func.isRequired
};

export default StrikerModal;
