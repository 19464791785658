import React from 'react';
import PropTypes from 'prop-types';
import HeadingGradient, { HEADINGS } from '@components/HeadingGradient';
import SimpleBlockContent from '@components/SimpleBlockContent';
import Link from '@components/Link';
import { getLowerDashed } from '@utils';

const OurDemands = ({ data, ...props }) => (
  <section {...props}>
    <HeadingGradient type={HEADINGS.h2} className="mb-8 sm:mb-16">
      {data.heading}
    </HeadingGradient>
    <SimpleBlockContent blocks={data._rawContent} />
    {data.ctas.map((cta) => (
      <Link
        id={`demands-cta-${getLowerDashed(cta.title)}`}
        className="w-full lg:w-auto"
        key={cta.link}
        href={cta.link}
      >
        {cta.title}
      </Link>
    ))}
  </section>
);

OurDemands.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    _rawContent: PropTypes.arrayOf(
      PropTypes.shape({
        blocks: PropTypes.arrayOf({ children: PropTypes.node })
      })
    ),
    ctas: PropTypes.arrayOf(PropTypes.shape())
  }).isRequired
};

export default OurDemands;
