import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import PropTypes from 'prop-types';

function SimpleBlockContent(props) {
  const { blocks } = props;

  if (!blocks) {
    console.error('Missing blocks');
    return null;
  }

  return (
    <div className="simple-block-content">
      <BlockContent blocks={blocks} />
    </div>
  );
}

SimpleBlockContent.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SimpleBlockContent;
