import React from 'react';
import PropTypes from 'prop-types';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

const SEO = ({ title, description, twitter, openGraphImageUrl }) => {
  return (
    <GatsbySeo
      title={title}
      description={description}
      twitter={{
        handle: twitter,
        site: twitter,
        cardType: 'summary_large_image'
      }}
      openGraph={{
        title,
        description,
        images: [{ url: openGraphImageUrl }]
      }}
    />
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  openGraphImageUrl: PropTypes.string.isRequired
};

export default SEO;
