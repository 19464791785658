import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import '../../tailwind.css';

export const HEADINGS = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6'
};

const HeadingGradient = ({ children, type, className }) => {
  const baseClassName =
    'font-sans font-bold text-center text-transparent bg-gradient-to-b from-biden-blue via-biden-blue to-biden-red';

  const content = children.split('\\n').map((text, index) => {
    // eslint-disable-next-line react/no-array-index-key
    return [text, <br key={index} />];
  });

  switch (type) {
    case HEADINGS.h1:
      return (
        <h1
          className={clsx(
            baseClassName,
            'text-h1 sm:text-8xl lg:text-10xl lg:pb-8 leading-tight',
            'heading-gradient',
            className
          )}
        >
          {content}
        </h1>
      );
    case HEADINGS.h2:
      return (
        <h2
          className={clsx(
            baseClassName,
            'text-h2 sm:text-7xl lg:text-9xl leading-tight',
            'heading-gradient',
            className
          )}
        >
          {content}
        </h2>
      );
    case HEADINGS.h3:
      return (
        <h3 className={clsx(baseClassName, 'heading-gradient', className)}>
          {content}
        </h3>
      );
    case HEADINGS.h4:
      return (
        <h4 className={clsx(baseClassName, 'heading-gradient', className)}>
          {content}
        </h4>
      );
    case HEADINGS.h5:
      return (
        <h5 className={clsx(baseClassName, 'heading-gradient', className)}>
          {content}
        </h5>
      );
    case HEADINGS.h6:
      return (
        <h6 className={clsx(baseClassName, 'heading-gradient', className)}>
          {content}
        </h6>
      );
    default:
      return null;
  }
};

HeadingGradient.defaultProps = {
  className: null
};

HeadingGradient.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default HeadingGradient;
