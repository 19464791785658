import React from 'react';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';
import SEO from '@components/SEO';
import FadeInAnimation from '@components/FadeInAnimation';
import Hero from '@sections/Hero';
import StrikersSection from '@sections/Strikers';
import OurDemandsSection from '@sections/OurDemands';
import MovementSection from '@sections/Movement';
import { Helmet } from 'react-helmet';
import dcLogo from '../images/dc-logo.svg';
import Footer from '../components/Footer';
import Header from '../components/Header';

const HOME_PAGE_LINK = 'http://debtcollective.org/';
const HOME_PAGE_QUERY = graphql`
  query HomePageQuery {
    allSanityPage {
      nodes {
        name
        heading
        title
        description
        twitter
        openGraphImage {
          asset {
            assetId
            url
          }
        }
        _rawContent
        sections {
          __typename
          ... on SanityPageSection {
            name
            heading
            _rawContent
            ctas {
              title
              link
            }
          }
          ... on SanityStrikersSection {
            name
            heading
            strikers {
              name
              city
              state
              totalDebt
              quote {
                children {
                  text
                }
              }
              avatar {
                asset {
                  fluid(maxWidth: 160) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Home = () => {
  const data = useStaticQuery(HOME_PAGE_QUERY);
  const homepage = data.allSanityPage.nodes[0];
  const pageSections = homepage.sections || [];
  const openGraphImageUrl = homepage.openGraphImage.asset.url;

  const ourDemandsSection = pageSections.find(
    (pageSection) => pageSection.name === 'OUR DEMANDS'
  );
  const strikersSection = pageSections.find(
    (pageSection) => pageSection.name === 'MEET THE STRIKERS'
  );
  const movementSection = pageSections.find(
    (pageSection) => pageSection.name === 'OUR MOVEMENT'
  );

  return (
    <>
      <Helmet
        link={[
          {
            rel: 'icon',
            sizes: '180x180',
            href: `${withPrefix('/')}favicon/apple-touch-icon.png`
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: `${withPrefix('/')}favicon/favicon-32x32.png`
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: `${withPrefix('/')}favicon/favicon-16x16.png`
          },
          {
            rel: 'mask-icon',
            sizes: '16x16',
            href: `${withPrefix('/')}img/safari-pinned-tab.svg`,
            color: '#ff4400'
          }
        ]}
      />
      <SEO
        title={homepage.title}
        description={homepage.description}
        twitter={homepage.twitter}
        openGraphImageUrl={openGraphImageUrl}
      />
      <main className="min-h-screen p-8 bg-black lg:px-32 lg:py-16">
        <div className="mx-auto max-w-7xl">
          <Header />
          <div className="mt-16 space-y-16 sm:space-y-24 lg:space-y-48 sections">
            <Hero id="hero" homepage={homepage} />
            {strikersSection && (
              <FadeInAnimation>
                <StrikersSection id="strikers" data={strikersSection} />
              </FadeInAnimation>
            )}
            {ourDemandsSection && (
              <FadeInAnimation>
                <OurDemandsSection data={ourDemandsSection} id="our-demands" />
              </FadeInAnimation>
            )}
            {movementSection && (
              <FadeInAnimation>
                <MovementSection id="movement" data={movementSection} />
              </FadeInAnimation>
            )}
          </div>
          <FadeInAnimation>
            <Footer logo={dcLogo} homepageLink={HOME_PAGE_LINK} />
          </FadeInAnimation>
        </div>
      </main>
    </>
  );
};

export default Home;
