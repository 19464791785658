export const extractQuoteFromSanityBlock = (quote) => {
  return quote?.[0]?.children?.[0]?.text;
};

export const blockScroll = () => {
  document.body.style.overflow = 'hidden';
  document.body.style.touchAction = 'none';
};

export const unblockScroll = () => {
  document.body.style.removeProperty('overflow');
  document.body.style.touchAction = 'auto';
};

export const neutralizeBrowserBackButton = (callback) => {
  if (typeof window !== 'undefined') {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      callback();
    };
  }
};

export const restoreBrowserBackButton = () => {
  if (typeof window !== 'undefined') {
    window.onpopstate = undefined;
  }
};

export const getLowerDashed = (str) => {
  if (typeof str !== 'string') return str;

  return str.replace(/\s/g, '-').toLocaleLowerCase();
};
