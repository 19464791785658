import React from 'react';
import PropTypes from 'prop-types';
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

function Footer({ logo, homepageLink }) {
  return (
    <footer>
      <a href={homepageLink} title="Debt Collective Home Site">
        <img
          src={logo}
          alt="The debt collective"
          className="block w-48 mx-auto mt-48 md:w-auto"
        />
      </a>
      <div
        id="social-links"
        className="flex content-center justify-center mt-12 space-x-8 text-4xl text-white"
      >
        <a
          id="link-twitter"
          aria-label="open Twitter profile"
          className="text-white transform scale-95 hover:scale-100"
          href="https://twitter.com/StrikeDebt"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="hidden">Twitter</span>
          <FaTwitter />
        </a>
        <a
          id="link-youtube"
          aria-label="open YouTube profile"
          className="text-white transform scale-95 hover:scale-100"
          href="https://www.youtube.com/c/DebtCollective"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="hidden">YouTube</span>
          <FaYoutube />
        </a>
        <a
          id="link-facebook"
          aria-label="open Facebook profile"
          className="text-white transform scale-95 hover:scale-100"
          href="https://www.facebook.com/DebtCollective"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="hidden">Facebook</span>
          <FaFacebook />
        </a>
        <a
          id="link-instagram"
          aria-label="open Instagram profile"
          className="text-white transform scale-95 hover:scale-100"
          href="https://www.instagram.com/thedebtcollective/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="hidden">Instagram</span>
          <FaInstagram />
        </a>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  logo: PropTypes.string.isRequired,
  homepageLink: PropTypes.string.isRequired
};

export default Footer;
