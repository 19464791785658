import React, { useEffect, useContext, useState } from 'react';
import {
  Slide,
  ButtonBack,
  ButtonNext,
  Slider,
  CarouselContext
} from 'pure-react-carousel';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { extractQuoteFromSanityBlock } from '@utils';
import { Striker } from '@types';
import { MAIN_STRIKERS_TO_SHOW } from '@constants';

const StrikersCarousel = ({ strikers, triggerShowAllStrikers }) => {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext?.state?.currentSlide || 0
  );

  useEffect(() => {
    const onSlideChange = () =>
      setCurrentSlide(carouselContext?.state?.currentSlide);

    carouselContext?.subscribe(onSlideChange);
    return () => carouselContext?.unsubscribe(onSlideChange);
  }, [carouselContext]);

  useEffect(() => {
    if (MAIN_STRIKERS_TO_SHOW === currentSlide) {
      triggerShowAllStrikers();
    }
  }, [currentSlide, triggerShowAllStrikers]);

  return (
    <>
      <Slider>
        {strikers.map((striker, index) => (
          <Slide key={striker.name} className="overflow-y-auto striker">
            {/* This image is only visible in small devices */}
            <div className="px-8 pt-8">
              <Img
                className="object-cover w-32 h-32 mx-auto overflow-hidden rounded-full"
                fluid={striker.avatar.asset.fluid}
                alt={striker?.name}
              />
            </div>
            <div className="px-8 pb-8 striker-content">
              <h3 className="mt-8 font-sans text-4xl font-bold text-center text-white">
                {striker?.name}
              </h3>
              <p className="mt-2 font-sans text-lg text-center text-white">
                {striker?.city}, {striker?.state}
              </p>
              <p className="mt-2 font-sans text-xl text-center text-white">
                <span className="text-2xl font-bold">{striker?.totalDebt}</span>{' '}
                of debt
              </p>
              <h4 className="mt-12 font-sans text-2xl font-bold text-white">
                MY LIFE WITHOUT DEBT
              </h4>
              <p className="mt-4 font-sans text-lg italic text-left md:text-xl">
                &quot;{extractQuoteFromSanityBlock(striker?.quote)}&quot;
              </p>
            </div>
          </Slide>
        ))}
      </Slider>
      {currentSlide !== 0 && (
        <ButtonBack className="absolute top-0 hidden h-full -left-32 md:block">
          <span
            id="modal-navigate-back"
            className="text-gray-300 material-icons"
            style={{
              fontSize: '5rem'
            }}
          >
            navigate_before
          </span>
        </ButtonBack>
      )}
      {currentSlide + 1 !== strikers.length && (
        <ButtonNext className="absolute top-0 hidden h-full -right-32 md:block">
          <span
            id="modal-navigate-next"
            className="text-gray-300 material-icons"
            style={{
              fontSize: '5rem'
            }}
          >
            navigate_next
          </span>
        </ButtonNext>
      )}
    </>
  );
};

StrikersCarousel.defaultProps = {
  strikers: []
};

StrikersCarousel.propTypes = {
  strikers: PropTypes.arrayOf(Striker),
  triggerShowAllStrikers: PropTypes.func.isRequired
};

export default StrikersCarousel;
