import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Button = ({ children, className, ...props }) => (
  <button
    type="button"
    className={clsx(
      'block py-3 mx-auto text-2xl font-bold text-white border-2 border-white rounded-lg px-7 md:py-4 md:px-8 md:text-4xl bg-gradient-to-r from-biden-blue via-biden-red to-biden-red',
      className
    )}
    {...props}
  >
    {children}
  </button>
);

Button.defaultProps = {
  className: null
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default Button;
